import { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  boxInner,
  help,
  boxHelp,
  messages,
  message,
  prefix,
  suffix,
  fieldset,
  decorator,
  box,
  icon,
  legend,
  boxOption,
  boxOptions,
  boxWrapper,
  boxLabel,
  options,
  checkboxes,
  $if,
  $extend,
  defaultIcon,
} from '../compose'

/**
 * Input definition for a checkbox(ess).
 * @public
 */
export const checkbox: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: /* #__PURE__ */ outer(
    $if(
      '$options == undefined',
      /**
       * Single checkbox structure.
       */
      /* #__PURE__ */ boxWrapper(
        /* #__PURE__ */ boxInner(/* #__PURE__ */ prefix(), /* #__PURE__ */ box(), /* #__PURE__ */ decorator(/* #__PURE__ */ icon('decorator')), /* #__PURE__ */ suffix()),
        $extend(/* #__PURE__ */ boxLabel('$label'), {
          if: '$label',
        })
      ),
      /**
       * Multi checkbox structure.
       */
      /* #__PURE__ */ fieldset(
        /* #__PURE__ */ legend('$label'),
        /* #__PURE__ */ help('$help'),
        /* #__PURE__ */ boxOptions(
          /* #__PURE__ */ boxOption(
            /* #__PURE__ */ boxWrapper(
              /* #__PURE__ */ boxInner(
                /* #__PURE__ */ prefix(),
                $extend(/* #__PURE__ */ box(), {
                  bind: '$option.attrs',
                  attrs: {
                    id: '$option.attrs.id',
                    value: '$option.value',
                    checked: '$fns.isChecked($option.value)',
                  },
                }),
                /* #__PURE__ */ decorator(/* #__PURE__ */ icon('decorator')),
                /* #__PURE__ */ suffix()
              ),
              $extend(/* #__PURE__ */ boxLabel('$option.label'), {
                if: '$option.label',
              })
            ),
            /* #__PURE__ */ boxHelp('$option.help')
          )
        )
      )
    ),
    // Help text only goes under the input when it is a single.
    $if('$options == undefined && $help', /* #__PURE__ */ help('$help')),
    /* #__PURE__ */ messages(/* #__PURE__ */ message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'box',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['options', 'onValue', 'offValue', 'optionsLoader'],
  /**
   * Additional features that should be added to your input
   */
  features: [
    options,
    checkboxes,
    defaultIcon('decorator', 'checkboxDecorator'),
  ],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: 'qje02tb3gu8',
}
