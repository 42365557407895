import { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  fileInput,
  fileItem,
  fileList,
  fileName,
  fileRemove,
  noFiles,
  files,
  $if,
  defaultIcon,
} from '../compose'

/**
 * Input definition for a file input.
 * @public
 */
export const file: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: /* #__PURE__ */ outer(
    /* #__PURE__ */ wrapper(
      /* #__PURE__ */ label('$label'),
      /* #__PURE__ */ inner(
        /* #__PURE__ */ icon('prefix', 'label'),
        /* #__PURE__ */ prefix(),
        /* #__PURE__ */ fileInput(),
        /* #__PURE__ */ fileList(
          /* #__PURE__ */ fileItem(
            /* #__PURE__ */ icon('fileItem'),
            /* #__PURE__ */ fileName('$file.name'),
            $if(
              '$value.length === 1',
              /* #__PURE__ */ fileRemove(
                /* #__PURE__ */ icon('fileRemove'),
                '$ui.remove.value + " " + $file.name'
              )
            )
          )
        ),
        $if('$value.length > 1', /* #__PURE__ */ fileRemove('$ui.removeAll.value')),
        /* #__PURE__ */ noFiles(/* #__PURE__ */ icon('noFiles'), '$ui.noFiles.value'),
        /* #__PURE__ */ suffix(),
        /* #__PURE__ */ icon('suffix')
      )
    ),
    /* #__PURE__ */ help('$help'),
    /* #__PURE__ */ messages(/* #__PURE__ */ message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'text',
  /**
   * An array of extra props to accept for this input.
   */
  props: [],
  /**
   * Additional features that should be added to your input
   */
  features: [
    files,
    defaultIcon('fileItem', 'fileItem'),
    defaultIcon('fileRemove', 'fileRemove'),
    defaultIcon('noFiles', 'noFiles'),
  ],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: '9kqc4852fv8',
}
